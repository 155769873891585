/* You can add global styles to this file, and also import other style files */
@import "splash.css";

#toast-container {
  bottom: 42px !important;
}

.mat-dialog-container {
  padding: 0 !important;
}


/* Provide sufficient contrast against white background */

@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";


a {
  color: #0366d6;
}

.bg-header {
  background: linear-gradient(269.79deg, #657C90 -13.11%, #333333 80.13%);
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

body {
  font-family: 'Segoe UI', Verdana, sans-serif !important;
  font-size: 12px !important;
}

.text-primary {
  color: #223A9B !important;
}

.treecontainer {
  background: rgba(221, 221, 221, 0.4) !important;
  border-right: 1px solid #CCCCCC !important;
  min-height: 95vh;
}

.mat-input-element {
  background: #FFFFFF !important;
  border: 1px solid #D1D1D1 !important;
  border-radius: 4px;
}

.mat-checkbox-frame {
  border-style: none !important;
}

.mat-column-star {
  padding-right: 0px !important;
}

.mat-paginator-container {
  padding: 6px 8px !important;
}

.mat-checkbox-background {
  visibility: hidden;
}

.mat-mdc-paginator-page-size {
  visibility: hidden;
}

.w-30rem {
  width: 30rem !important;
}

.p-fileupload-buttonbar {
  padding: 0.75rem !important;
}

.p-button {
  padding: 0.50rem 1rem !important;
}

.f-16px {
  font-size: 16px !important;
}

.p-dropdown {
  height: 35px !important;
}

.userIcons {
  font-size: 1rem;
  padding-right: 10px;
  position: relative;
  top: 2px;
}

.mdc-line-ripple {
  visibility: hidden;
}

.card {
  border: none !important;
}

.mdc-checkbox__background {
  width: 16px !important;
  height: 16px !important;
}

.p-tree {
  background: rgba(221, 221, 221, 0.4);
  padding: unset;
  border: none;
  min-height: 300px !important;
}

.p-component {
  font-size: 12px;
}

.m-3 {
  margin-left: unset !important;
}

.p-paginator {
  justify-content: left !important;
}

.p-link {
  font-size: unset;
}

.p-treenode-content {
  padding: unset !important;
}

.pi-filter-icon {
  /* position: relative !important;
  top: 4px !important;*/
}

.pi {
  font-size: unset;
}

.p-button-label {
  font-weight: 400;
}

.p-button {
  /*color: black;
    background:none;
    border: none;*/
}

.customBtn {
  color: black;
  background: none;
  border: none;
}

  .customBtn:enabled:hover {
    background: none;
    color: black;
  }

.p-inputtext {
  padding: 0.5rem 0.5rem;
  font-size: 12px;
}

div.p-fileupload-row > div:nth-child(1) {
  display: none;
}

div.p-fileupload-row > div:nth-child(2) {
  width: fit-content;
}

.p-breadcrumb {
  border: none;
  padding-left: unset;
  background: unset !important;
}

.container-fluid {
  padding-left: unset;
  /*z-index:1005;*/
}

.example-container {
  min-height: 100px;
}

.example-sidenav-content {
  display: flex;
  height: 100%;
}

  .example-sidenav-content > button {
    position: absolute;
    top: 0px;
    left: 0px;
  }

.example-sidenav {
  padding: 5px;
}

.mat-drawer {
  min-width: 350px
}

.drawerBtn {
  background-color: rgba(221, 221, 221, 0.4);
  position: relative;
  left: -10px;
  height: 40px;
  border: 1px solid #cccccc;
}

  .drawerBtn:enabled:hover {
    background-color: rgba(221, 221, 221, 0.4);
    border-color: unset;
  }

  .drawerBtn:focus {
    box-shadow: unset;
  }

.mat-drawer-inner-container {
  overflow: unset !important;
}

.mat-drawer {
  overflow-y: unset !important;
}

.mat-drawer-content {
  overflow: unset !important;
}

.bg-blue-100 {
  background-color: #cae6fc !important;
}

.bg-blue-200 {
  background-color: var(--blue-200);
  color: white;
}

.bg-yellow-100 {
  background-color: var(--yellow-100) !important;
}


.mat-drawer-container {
  overflow: unset !important;
}
/*This is a temp fix for right hand side drawer*/
div.p-component-overlay.p-sidebar-mask.p-component-overlay-enter {
  width: 0;
}


.p-menu {
  top: 111px !important;
}

.p-menuCustom {
  top: 111px !important;
  left: 1200px !important;
}

.splitbuttonCustom {
  position: relative;
  top: 5px;
}

.p-splitbutton-defaultbutton {
  font-size: 12px !important;
  color: black !important;
}

.p-tieredmenu {
  left: -75px !important;
  width: 20rem;
}

.p-splitbutton-menubutton {
  display: none !important;
}

.p-button:focus {
  box-shadow: 0 0 0 2px #ffffff;
}

.p-multiselect {
  width: 100%;
  margin-top: 5px;
  height: 35px;
}

  /*.p-tree-filter-container {
  margin-top: 5px;
}*/

  .p-multiselect .p-multiselect-label {
    padding: 0.50rem 0.50rem;
  }

.p-treenode-content {
  cursor: pointer;
}

.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  padding: 0.35rem 0.35rem;
}

.focus {
  border-color: rgba(126, 239, 104, 0.8) !important;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(126, 239, 104, 0.6);
  outline: 0 none;
}

.required-field {
  color: red;
  font-size: 1.2em;
  margin-left: 0.2em;
}


/*#pr_id_1-table > thead > tr:nth-child(2) > th:nth-child(2) > p-columnfilter > div > p-columnfilterformelement > input {
    height:30px;
}*/

p-columnfilterformelement > input.p-inputtext.p-component.p-element.ng-star-inserted {
  height: 30px;
}

p-columnfilterformelement > p-calendar > span > input {
  height: 30px;
}

.p-fileupload .p-fileupload-content {
  padding-right: 1rem;
  padding-left: 1rem;
}

.h-35px {
  height: 35px !important;
}

.w-35px {
  width: 35px !important;
}


.promptWorkflowBorder .p-dropdown {
  //border-color: red !important;
}

.mat-drawer.mat-drawer-side {
  z-index: 1 !important;
}

.p-datatable-loading-overlay.p-component-overlay {
  background-color: rgba(0, 0, 0, 0) !important;
}

.p-tree-loading-overlay.p-component-overlay {
  background-color: rgba(0, 0, 0, 0) !important;
}

.buttonDisabledShadow {
  position: relative;
  color: var(--mdc-filled-button-disabled-label-text-color, rgba(0, 0, 0, 0.38));
  background-color: var(--mdc-filled-button-disabled-container-color, rgba(0, 0, 0, 0.12));
  border-radius: var(--mdc-filled-button-container-shape, var(--mdc-shape-small, 4px));
}

div.col.text-end > p-button > button {
  color: black;
}

/*.wrap-header {
  white-space: normal !important;
}*/

.p-tree .p-tree-wrapper {
  height: 74vh;
}

.p-fileupload-content .p-fileupload-files .ng-star-inserted {
  display: flex;
  flex-wrap: wrap;
}

div.p-component-overlay.p-sidebar-mask.p-component-overlay-enter {
  width: calc(100% - 480px);
  overflow: visible;
}


.p-breadcrumb ul li .p-menuitem-link .p-menuitem-text {
  color: #0366d6 !important;
}

.footer {
  bottom: 0px;
  position: absolute;
  width: 100%;
  z-index: 100;
}

.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
  padding-left: 0px !important;
}

.mat-drawer-container {
  background-color: white !important;
}

.btn-container-row {
  --bs-gutter-x: 0rem !important;
  gap: 1rem;
}

homeicon svg.p-icon.p-menuitem-icon {
  display: none;
}

.p-tree-empty-message {
  top: 8.5rem;
  position: absolute;
  left: 0.2rem;
}

.p-datatable-loading-icon {
  width: 2rem;
  height: 2rem;
  top: 11rem;
  position: absolute;
}

.mdc-button__label {
  font-size: 0.7rem;
}

.p-fileupload .p-fileupload-buttonbar .p-button {
  gap: 0.5rem !important;
}

.p-component {
  font-size: 12px !important;
}

.mdc-button {
  font-family: 'Segoe UI', Verdana, sans-serif !important;
  font-size: 12px !important;
}
