html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
}
.loading-bar-dots {
  position: relative;
  height: 10px;
  width: 500px;
  display: flex;
}
.loading-bar-dots .dot {
  width: 10px;
  height: 10px;
  background: #274299;
  display: inline-block;
  position: relative;
  border-radius: 50%;
  opacity: 0;
  animation: loading 2500ms ease-in-out infinite;
}
.loading-bar-dots .dot:not(:last-child) {
  margin-right: 5px;
}
.loading-bar-dots .dot:nth-last-child(5) {
  animation-delay: 500ms;
}
.loading-bar-dots .dot:nth-last-child(4) {
  animation-delay: 400ms;
}
.loading-bar-dots .dot:nth-last-child(3) {
  animation-delay: 300ms;
}
.loading-bar-dots .dot:nth-last-child(2) {
  animation-delay: 200ms;
}
.loading-bar-dots .dot:nth-last-child(1) {
  animation-delay: 100ms;
}
.app-loading-shell {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: white;
  z-index: 1000;
}
.app-loading-shell.finished-loading {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.3s, opacity 0.3s linear;
}
.app-loading-shell .app-loading {
  height: 100%;
  width: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.app-loading-shell .app-loading div:not(:last-child) {
  margin-bottom: 10px;
}
.app-loading-shell .app-loading .logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
.app-loading-shell .app-loading .logo img {
  width: 500px;
}
.app-loading-shell .app-loading .app-title {
  display: flex;
}
.app-loading-shell .app-loading .app-title h1 {
  margin: 0;
  font-size: 60px;
  color: #ed1a3b;
  font-weight: bold;
  font-family: 'Segoe UI', Verdana, sans-serif !important;
  max-width: 700px;
  text-align: center;
}
@keyframes loading {
  0% {
    transform: translateX(0);
    opacity: 0;
  }
  35%,
  65% {
    transform: translateX(215px);
    opacity: 1;
  }
  100% {
    transform: translateX(430px);
    opacity: 0;
  }
}